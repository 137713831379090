// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alieninvasion-js": () => import("./../../../src/pages/alieninvasion.js" /* webpackChunkName: "component---src-pages-alieninvasion-js" */),
  "component---src-pages-getyouraxetogether-js": () => import("./../../../src/pages/getyouraxetogether.js" /* webpackChunkName: "component---src-pages-getyouraxetogether-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-knowyourvote-js": () => import("./../../../src/pages/knowyourvote.js" /* webpackChunkName: "component---src-pages-knowyourvote-js" */),
  "component---src-pages-pidashboard-js": () => import("./../../../src/pages/pidashboard.js" /* webpackChunkName: "component---src-pages-pidashboard-js" */),
  "component---src-pages-solar-js": () => import("./../../../src/pages/solar.js" /* webpackChunkName: "component---src-pages-solar-js" */),
  "component---src-pages-traffictracer-js": () => import("./../../../src/pages/traffictracer.js" /* webpackChunkName: "component---src-pages-traffictracer-js" */)
}

